<template>
  <div class="page_bg">
    <!-- 顶部返回 -->
    <div class="back_top_name flex-r-sb-c" style=" padding: 0.32rem;background: #ffffff;font-size: 0.48rem;">
      <van-icon name="arrow-left" @click="backPath" />
      <div class="top_name">编辑收货地址</div>
      <van-icon v-if="id !== '0'" name="delete-o" color="#EB561F" @click="delAddress()" />
      <div v-else />
    </div>
    <!--  -->
    <div class="edit_address_box">
      <van-form>
        <van-field v-if="id === '0'" v-model="addressInfo.message" rows="4" autosize type="textarea" placeholder="粘贴信息，自动拆分姓名、电话和地址">
          <template #button>
            <van-button round block type="addressInfo" color="#0767AB" size="mini" @click="getAddressInfo">识别地址
            </van-button>
          </template>
        </van-field>
        <!-- <van-field v-model="addressInfo.receiptCompany" label="采购公司" placeholder="请填写公司名称" /> -->
        <van-field v-model="addressInfo.receiptPersonName" label="采购人员" placeholder="请填写采购人员姓名" />
        <van-field v-if="flag === 1" v-model="addressInfo.receiptMobile" type="number" maxlength="11" label="联系方式" placeholder="请填写联系方式" />
        <van-field v-if="flag === 2" v-model="addressInfo.receiptMobile" type="text" label="联系方式" placeholder="请填写联系方式" />
        <van-field v-model="addressInfo.districtName" readonly clickable label="收货地址" placeholder="请选择所在地区"
                   right-icon="arrow" @click="showPicker = true"
        />
        <van-field v-model="addressInfo.receiptAddress" label="详细地址" placeholder="请填写详细地址" />
        <van-field v-model="addressInfo.companyPosition" label="默认收货地址" input-align="right">
          <template #input>
            <van-switch v-model="addressInfo.switchChecked" size="0.6rem" active-color="#0767AB"
                        inactive-color="#dcdee0"
            />
          </template>
        </van-field>
        <div class="sub_btn" style="margin: 16px;margin-top: 2rem;">
          <van-button round block type="addressInfo" color="#0767AB" @click="onSubmit">保存地址</van-button>
        </div>
      </van-form>
    </div>
    <!-- 省市区部分 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-cascader v-model="cascadervalue" title="请选择所在地区" :options="columns" :field-names="fieldNames"
                    @close="closePick" @finish="finishPick"
      />
    </van-popup>
  </div>
</template>

<script>
import { districts } from '@/api/public'
import { userReceiptAddress_getaddress, userReceiptAddress_del, userReceiptAddress_addAndUpdate, parseAddress } from '@/api/user'
export default {
  data() {
    return {
      id: '',
      flag: '',
      addressInfo: {
        districtName: '', provinceId: '', cityId: '', areaId: '', receiptPersonName: '', receiptMobile: '', receiptAddress: '', message: ''
      },
      // 省市区
      showPicker: false,
      cascadervalue: [],
      columns: [],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      }
    }
  },
  created() {
    this.id = this.$route.query.id
    this.flag = this.$route.query.flag ? parseInt(this.$route.query.flag) : 1
    if (this.id !== '0') { this.loadDate() }
    this.loadDistrict()
  },
  methods: {
    backPath() { window.history.back() },
    loadDate() {
      userReceiptAddress_getaddress(this.id).then(res => {
        this.addressInfo = res.data
        // 获取省市区名称
        this.addressInfo.districtName = res.data.province.provinceName + '-' + res.data.city.cityName + '-' + res.data.area.areaName
        this.addressInfo.provinceId = res.data.province.provinceId
        this.addressInfo.cityId = res.data.city.cityId
        this.addressInfo.areaId = res.data.area.areaId
        // 默认地址修改
        this.$set(this.addressInfo, 'switchChecked', res.data.isDefault === 1)
      })
    },
    delAddress() {
      this.$dialog.confirm({
        title: '注意',
        message: '是否删除当前收货地址'
      })
        .then(() => {
          userReceiptAddress_del(this.id).then(res => {
            this.$toast({ message: '删除成功！', duration: 1 * 1000 })
            this.$utils.pathGoBack(-1)
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    onSubmit() {
      // 默认地址修改
      this.$set(this.addressInfo, 'isDefault', this.addressInfo.switchChecked ? 1 : 0)
      // 数据整合
      this.$set(this.addressInfo, 'userReceiptAddressId', this.id !== '0' ? this.id : '')
      this.$set(this.addressInfo, 'company', this.addressInfo.receiptCompany)
      userReceiptAddress_addAndUpdate(this.addressInfo).then(res => {
        this.$toast({ message: '修改成功！', duration: 1 * 1000 })
        this.backPath()
      })
    },
    // 省市区
    // 加载省市区
    loadDistrict() { districts().then(res => { this.columns = res.data }) },
    // 选择结束
    finishPick(value) {
      console.log(value)
      this.showPicker = false
      this.addressInfo.districtName = value.selectedOptions[0].label + '-' + value.selectedOptions[1].label + '-' + value.selectedOptions[2].label
      this.addressInfo.provinceId = value.selectedOptions[0].value
      this.addressInfo.cityId = value.selectedOptions[1].value
      this.addressInfo.areaId = value.selectedOptions[2].value
    },
    // 关闭选择
    closePick() {
      this.showPicker = false
    },
    // 识别地址
    getAddressInfo() {
      parseAddress(this.addressInfo.message).then(res => {
        console.log(res.data)
        if (!res.data.empty) {
          this.addressInfo.districtName = res.data.province + '-' + res.data.city + '-' + res.data.area
          this.addressInfo.provinceId = res.data.provinceId
          this.addressInfo.cityId = res.data.cityId
          this.addressInfo.areaId = res.data.areaId
          this.addressInfo.receiptPersonName = res.data.name
          this.addressInfo.receiptMobile = res.data.mobile
          this.addressInfo.receiptAddress = res.data.address
          this.$forceUpdate()
        } else {
          this.$toast({ message: '无识别内容', duration: 1 * 1000 })
        }
      })
    }
  }
}
</script>

<style>
</style>
